<template >
  <ion-page>
    <Header type="0" title="打印机管理"> </Header>

    <ion-content
      scroll-y="true"
      class="content"
      :class="{ 'scan-container': isOpenScan }"
      id="ProjectMainProjectListOut"
    >
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>打印机</div>
          <div class="right">
            <ion-select
              placeholder="请选择打印机"
              v-model="queryParam.id"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
            >
              <ion-select-option
                v-for="(item, index) in ckdOptions"
                :key="index"
                :value="item.id"
                >{{ item.nameCN }}</ion-select-option
              >
            </ion-select>
            <!-- <ion-text @click="openQrScanner">扫一扫</ion-text> -->
          </div>
        </div>
      </div>
    </ion-content>
    <person-choose @chooseCode="searchPerson"></person-choose>
    <search-task-code-out @chooseCode="chooseCode"></search-task-code-out>
    <ion-footer style="background: #fff">
      <ion-button expand="block" @click="commit">保存</ion-button>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  menuController,
  loadingController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import qrScanner from "@/components/cordova/qrScanner.vue";
import {
  getPrinterIP,
  getPrinter,
  insertPrinter_pda,
  createPdaInfo,
} from "@/api/login/login";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils.ts";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    ionAlert,
  },
  data() {
    return {
      queryParam: {},
      ckdOptions: [],
    };
  },

  async mounted() {
    await this.getList();
    console.log(this.ckdOptions)
    this.getInfo();
  },
  methods: {
    createPrint() {
      createPdaInfo({
        nameCN: SystemUtils.loginUser.id,
        userId: SystemUtils.loginUser.id,
        user: SystemUtils.loginUser.name,
      }).then((res) => {
        if (res.data.data.isSuccess) {
        }
      });
    },
    getInfo() {
      getPrinterIP({ nameCN: SystemUtils.loginUser.id }).then((res) => {
        if (!res.data.data.resultData) {
          this.createPrint();
        } else {
          var data = this.ckdOptions.filter((e) => {
            return (e.nameCN == res.data.data.resultData.nameCN);
          });
          this.queryParam.id = data[0].id
        }
      });
    },
    getList() {
      return new Promise((resolve) => {
        getPrinter().then((res) => {
          this.ckdOptions = res.data.data.resultData;
          resolve();
        });
      });
    },
    async commit() {
      var _this = this;
      if (!this.queryParam.id) {
        Utils.presentToastTopWarning("请选择打印机", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否保存打印机设置",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();

              insertPrinter_pda({
                nameCN: SystemUtils.loginUser.id,
                printerInfoId: _this.queryParam.id,
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
.selectBtn {
  width: 60px;
  height: 30px;
}
.title-canvars {
  background-color: #ffffff;
  font-size: px2rem(20);
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.canvars-style {
  margin: 0 auto;
  border: 1px solid #999;
  border-radius: 10px;
  overflow: hidden;
  width: calc(100% - #{px2rem(20)});
  height: 100px;
  background: #fff;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
</style>